import React from 'react';
import './Hotels.css';
import { MdHistoryToggleOff, MdArrowForward, MdFreeBreakfast, MdOutlineWifi, MdSportsGymnastics } from "react-icons/md";
import { Link } from 'react-router-dom';
const Hotel = () => {
    return (
        <div className='hotel'>
            <div className='hotel-enjoy'>
                <div className='hotelCol-left'>
                    <h1>
                        Enjoy your stay<br />
                        at our hotel
                    </h1>

                    <div className='hoteldivider' />
                    <div className='hoteldivider middle' />
                    <div className='hoteldivider' />

                    <p className='hotelDesc'>
                        We are more than being a hotel because we are able
                        to combine the quality standard of a hotel with the
                        advantages of an apartment.
                    </p>
                </div>


                <div className='hotelCol-right'>
                    <div className='hotelBoxLeft'>
                        <div className='serviceCol'>
                            <div className='hours'>
                                <MdHistoryToggleOff size={40} className='serviceIcons' />
                            </div>
                            <div className='hoursContent'>
                                <span className='serviceTitle'>24 hours Room Service</span>
                                <p className='serviceDesc'>
                                    You have access to 24-hours a day room service at our hotel.
                                </p>
                            </div>
                        </div>

                        <div className='serviceCol'>
                            <div className='resatuarant'>
                                <MdFreeBreakfast size={40} className='serviceIcons' />
                            </div>
                            <div className='resContent'>
                                <span className='serviceTitle'>Restaurant and Bars</span>
                                <p className='serviceDesc'>
                                    You have access to the world state of art restaurants and bars at our hotel.
                                </p>
                            </div>
                        </div>
                    </div>



                    <div className='hotelBoxRight'>
                        <div className='serviceCol'>
                            <div className='hours'>
                                <MdSportsGymnastics size={40} className='serviceIcons' />
                            </div>
                            <div className='hoursContent'>
                                <span className='serviceTitle'>Fitness &amp; Spa</span>
                                <p className='serviceDesc'>
                                    Access to fitness and Spa is part of our hotel package when you make a booking.
                                </p>
                            </div>
                        </div>

                        <div className='serviceCol'>
                            <div className='resatuarant'>
                                <MdOutlineWifi size={40} className='serviceIcons' />
                            </div>
                            <div className='resContent'>
                                <span className='serviceTitle'>Free Wi-Fi Access</span>
                                <p className='serviceDesc'>
                                    You have access to 24-hours free Wi-Fi services irrespective of any room.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='hotel-branch'>
                <h1>OUR HOTELS</h1>

             
                    <div className='hotel-box-wrap'>
                        <div className="hotel-box">
                            <div className='hotel-box-img'>
                                <img src="/assets/ayalla-yenagoa.jpeg" alt="ayalla yenagoa" />
                            </div>
                            <div className='hotel-box-text'>
                                <h2>Yenagoa City</h2>
                            </div>
                            {/* link */}
                            <div className='hotel-box-link'>
                                <Link to="/yenagoa-hotel" className="link">
                                    <MdArrowForward size={25} />
                                </Link>
                            </div>
                        </div>



                        <div className="hotel-box">
                            <div className='hotel-box-img'>
                                <img src="/assets/ayalla-hotels-ltd.jpg" alt="ayalla abuja" />
                            </div>
                            <div className='hotel-box-text'>
                                <h2>Abuja City</h2>
                            </div>
                             {/* link */}
                             <div className='hotel-box-link'>
                                <Link to="/abuja-hotel" className="link">
                                    <MdArrowForward size={25} />
                                </Link>
                            </div>
                        </div>




                        <div className="hotel-box">
                            <div className='hotel-box-img'>
                                <img src="/assets/ayalla-vip.jpg" alt="ayalla business vip" />
                            </div>
                            <div className='hotel-box-text'>
                                <h2>Ayalla Business Suites</h2>
                            </div>
                             {/* link */}
                             <div className='hotel-box-link'>
                                <Link to="/ayalla-vip-annex" className="link">
                                    <MdArrowForward size={25} />
                                </Link>
                            </div>
                        </div>


                    </div>
              

            </div>

        </div>
    )
}

export default Hotel;