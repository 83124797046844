import React from 'react';
import { MdRoom, MdCall, MdMarkEmailRead } from "react-icons/md";
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <div className='footer'>
            <div className='footerWrapper'>
                <div className='footerLeft'>
                    <h1>if you have any <br/>questions don&lsquo;t hesitate to contact us.</h1>
                </div>

                <div className='footerRight'>
                    <h2>CONTACT INFORMATION</h2>
                    <div className='footerRightContent'>
                        <div className='footerAddress'>
                        <div className='footerIcon'>
                            <MdRoom size={30} className="footerIcons"/>
                            </div>
                            <div className='footerAddressContent'>
                                <span>Yenagoa</span>
                                <p>
                                Isaac Boro Expressway, Kpansia, Yenagoa, Bayelsa State.
                                </p>
                            </div>
                        </div>


                        <div className='footerAddress'>
                        <div className='footerIcon'>
                            <MdRoom size={30} className="footerIcons"/>
                            </div>
                            <div className='footerAddressContent'>
                                <span>Abuja</span>
                                <p>
                                Off Amadu Bello Way Garki Area II, Ibi Close Behind KIA Motors, Abuja State.
                                </p>
                            </div>
                        </div>


                        <div className='footerAddress'>
                            <div className='footerIcon'>
                            <MdCall size={30} className="footerIcons"/>
                            </div>
                            <div className='footerAddressContent'>
                                <span>24/7 hrs Support Line</span>
                                <p>
                                {/* +234 (81) 3333-9034, <br/> +234 (80) 3705-7740 */}
                                +23480-6450-7727
                                </p>
                            </div>
                        </div>
                        

                        <div className='footerAddress'>
                            <MdMarkEmailRead size={30} className="footerIcons"/>
                            <div className='footerAddressContent'>
                                <span>e-mail</span>
                                <p>
                                ayallahotels@yahoo.com <br/>info@ayallahotels.com
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='footerImage'>
                    <img src="/assets/ceo.png" alt="robert ayalla" />
                </div>
            </div>

            <div className='footerBottom'>
                <span> &copy; {new Date().getFullYear()} Copyright Ayalla Hotels Limited. All Right Reserved.</span>
                <Link to="https://wa.me/2347063938352?text=Yello!%20Welcome%20to%20Softmerit%20ICT%20Solutions.%20Your%20Technology%20Plug!" className='link'>
                <span className='developer'>Developed By: SOFTMERIT ICT SOLUTIONS</span>
                </Link>
            </div>
        </div>
    )
}

export default Footer;